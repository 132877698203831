<template>
	<div class="ns-groupbuy">
		<el-carousel height="400px" v-loading="loadingAd" v-if="adList.length">
			<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
		</el-carousel>

		<!-- รายการผลิตภัณฑ์ -->
		<div class="ns-groupbuy-box" v-loading="loading">
			<div class="ns-groupbuy-title" v-if="goodsList.length">
				<i class="iconfont icontuangou"></i>
				<span style="font-weight: 600;     font-family: 'Prompt';">สินค้าขายยกแพ็ค</span>
			</div>

			<div class="goods-list" v-if="goodsList.length">
				
				
				
				
				
				
				
				
				
				<div class="MuiBox-root css-0">
					<div class="MuiBox-root css-1b6m7hw" id="product_list-9"></div>
					<div class="PrivateHiddenCss-root css-0">
						<div class="MuiContainer-root MuiContainer-maxWidthLg css-1vrveu8">
							<div class="MuiBox-root css-0" style="margin-bottom: 0px;">
								<div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1 MuiGrid-spacing-sm-2 css-1ye9ly5" style="padding-top: 0px;">
									<div v-for="item in goodsList" :key="item.id"  class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-4 MuiGrid-grid-md-3 MuiGrid-grid-lg-auto css-1raj6c1">
										<div class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root product-card css-1mrtwsr">
											<a class="MuiBox-root css-t88my0"  ></a>
											<a >
												<div class="MuiBox-root css-1kxonj9">
													<div class="MuiBox-root css-0"><span style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
													<span style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27228%27%20height=%27230%27/%3e" style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span>
													
													<img :src="$img(item.sku_image, { size: 'mid' })" lazy @error="imageError(index)" decoding="async" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" @click="$router.pushToTab('/promotion/groupbuy-' + item.groupbuy_id)" >
														<noscript></noscript>
														</span>
													</div>
													<div class="MuiBox-root css-1ncwqrm"><span style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative;"><span style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 100% 0px 0px;"></span>
													<img alt="asus" src="https://mercular.s3.ap-southeast-1.amazonaws.com/images/brands/2018/08/asus.jpg?w=3840" decoding="async" data-nimg="responsive" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" ></span>
													</div>
												</div>
											</a>
											<div class="css-1br9xiw">
												<div class="MuiBox-root css-0" style="height: 32px; margin-top: -13px;"> </div>
											</div>
											<a  @click="$router.pushToTab('/promotion/groupbuy-' + item.groupbuy_id)" >
												<div class="MuiBox-root css-0">
													<div class="css-145il57"><span class="MuiTypography-root MuiTypography-body1 css-y8muc4">{{ item.goods_name }}</span></div>
													<div class="css-148xglp" style="    margin-top: 0.9699999999999996vw;
    margin-bottom: -17.73px;">
														<div class="MuiChip-root MuiChip-filled MuiChip-sizeSmall MuiChip-colorError MuiChip-filledError css-19zcdyn"><span class="MuiChip-label MuiChip-labelSmall css-tavflp">ยกแพ็ค {{ item.buy_num }} ชิ้น</span></div>
														<div class="css-1krjymi">
															<div class="MuiTypography-root MuiTypography-subtitle1 css-xnjhch">฿{{ item.groupbuy_price }}</div>
															<div class="MuiTypography-root MuiTypography-subtitle1 css-kvr42m">฿{{ item.price }}</div>
														</div>
													</div>
													<div class="MuiTypography-root MuiTypography-body1 css-kvc1z8"> </div>
												</div>
											</a>
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
				
				
				
				
				
				
				
				
				
			</div>
			<div v-else><div class="ns-text-align">เรายังไม่มีสินค้าสำหรับการขายยกแพ็คในตอนนี้โปรดกลับมาภายหลัง</div></div>

			<div class="pager">
				<el-pagination
					background
					:pager-count="5"
					:total="total"
					prev-text="ก่อน"
					next-text="ต่อไป"
					:current-page.sync="currentPage"
					:page-size.sync="pageSize"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange"
					hide-on-single-page
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { goodsPage } from '@/api/groupbuy';
import { mapGetters } from 'vuex';
import { adList } from '@/api/website';

export default {
	name: 'groupbuy',
	components: {},
	data: () => {
		return {
			loading: true,
			goodsList: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			loadingAd: true,
			adList: []
		};
	},
	created() {
		if (this.addonIsExit && this.addonIsExit.groupbuy != 1) {
			this.$notify({
				message: 'ไม่ได้ติดตั้งปลั๊กอินการซื้อกลุ่ม',
				title: 'คำเตือน',type: 'warning',
				duration: 2000,
				onClose: () => {
					this.$route.push('/');
				}
			});
		} else {
			this.getAdList();
			this.getGoodsList();
		}
	},
	computed: {
		...mapGetters(['defaultGoodsImage', 'addonIsExit'])
	},
	watch: {
		addonIsExit() {
			if (this.addonIsExit.groupbuy != 1) {
				this.$notify({
					message: 'ไม่ได้ติดตั้งปลั๊กอินการซื้อกลุ่ม',
					title: 'คำเตือน',type: 'warning',
					duration: 2000,
					onClose: () => {
						this.$route.push('/');
					}
				});
			}
		}
	},
	methods: {
		getAdList() {
			adList({ keyword: 'NS_PC_GROUPBUY' })
				.then(res => {
					this.adList = res.data.adv_list;
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
					}
					this.loadingAd = false;
				})
				.catch(err => {
					this.loadingAd = false;
				});
		},
		/**
		 * การซื้อสินค้าแบบกลุ่ม
		 */
		getGoodsList() {
			goodsPage({
				page_size: this.pageSize,
				page: this.currentPage
			})
				.then(res => {
					console.log(res,'การซื้อสินค้าแบบกลุ่ม')
					this.goodsList = res.data.list;
					this.total = res.data.count;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$notify.error({title: 'Error',message: err.message});
				});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getGoodsList();
		},
		/**
		 * การโหลดรูปภาพล้มเหลว
		 */
		imageError(index) {
			this.goodsList[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>
<style lang="scss" scoped>
.ns-groupbuy {
	background: #ffffff;
	.ns-groupbuy-box {
		padding-top: 54px;
		width: $width;
		margin: 0 auto;

		.ns-groupbuy-title {
			width: 100%;
			border-bottom: 1px solid $base-color;
			padding-bottom: 10px;

			i {
				font-size: 32px;
				color: $base-color;
			}

			span {
				font-size: 30px;
				font-family: 'BDZongYi-A001';
				font-weight: 600;
				color: $base-color;
				margin-left: 15px;
			}
		}
	}
	.goods-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-top: 45px;

		.item {
			width: 20%;
			padding: 0 6px;
			box-sizing: border-box;
			margin-bottom: 16px;

			.goods {
				width: 100%;
				border: 1px solid #e9e9e9;
				background-color: #ffffff;
				overflow: hidden;
				color: #303133;
				transition: 0.3s;
				padding: 10px;
				box-sizing: border-box;
				cursor: pointer;
			}
		}

		.img {
			width: 100%;
			height: 100%;

			.el-image {
				width: 100%;
				height: 208px;
				.el-image__error {
					width: 100%;
					height: 100%;
				}
			}
		}

		.price {
			p {
				display: flex;
				align-items: flex-end;
				height: 24px;
				color: $base-color;
				margin: 10px 0 5px;

				span:first-child {
					font-size: 20px;
					font-family: 'BDZongYi-A001';
					font-weight: 600;
					margin-right: 5px;
					line-height: 24px;
				}
				span:nth-child(2) {
					line-height: 14px;
				}
			}

			.main_price {
				color: $base-color;
				font-size: 25px;
				line-height: 24px;
				font-weight: 500;
			}

			.primary_price {
				text-decoration: line-through;
				color: $base-color-info;
				margin-left: 5px;
			}
		}

		.name {
			font-size: 14px;
			line-height: 1.4;
			margin-bottom: 5px;
			white-space: normal;
			overflow: hidden;
			
			p {
				line-height: 24px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				height: 50px;
			}
		}
	}
}
</style>
<style lang="scss">
.ns-groupbuy {
	.el-carousel {
		.el-image__inner {
			width: auto;
		}
	}
	.el-carousel__arrow--right{
		right: 60px;
	}
}/*! CSS Used from: https://www.mercular.com/globals.css */
a{color:inherit;text-decoration:none;}
*{box-sizing:border-box;scrollbar-color:#9E9E9E transparent;scrollbar-width:none;}
html ::-webkit-scrollbar{width:8px!important;}
:not(html)::-webkit-scrollbar{width:6px;}
::-webkit-scrollbar-track{background:transparent;}
::-webkit-scrollbar-thumb{background-color:#9E9E9E;border-radius:8px;}
::-webkit-scrollbar-thumb:hover{background-color:#9E9E9E;transition:background-color .3s;}
/*! CSS Used from: Embedded */
*,*::before,*::after{box-sizing:inherit;}
/*! CSS Used from: Embedded */
.css-1b6m7hw{position:relative;}
@media (min-width:0px){
.css-1b6m7hw{top:-80px;}
}
@media (min-width:900px){
.css-1b6m7hw{top:-150px;}
}
.css-1mrtwsr{background-color:#fff;color:rgba(0, 0, 0, 0.87);-webkit-transition:box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;transition:box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;border-radius:4px;box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);overflow:hidden;max-width:236px;border:1px solid rgba(224, 224, 224, 1);border-radius:16px;position:relative;box-shadow:unset;-webkit-flex:1 0 auto;-ms-flex:1 0 auto;flex:1 0 auto;}
@media (min-width:0px){
.css-1mrtwsr{padding:8px 8px 10px 8px;}
}
@media (min-width:600px){
.css-1mrtwsr{padding:15px 10px 10px 10px;}
}
@media (min-width:900px){
.css-1mrtwsr{padding:15px 12px 10px 12px;}
}
@media (min-width:1280px){
.css-1mrtwsr{width:auto;}
}
.css-1mrtwsr:hover{display:block;box-shadow:0px 30px 40px rgba(0, 0, 0, 0.1);}
.css-t88my0{position:absolute;height:100%;width:100%;top:0;bottom:0;left:0;right:0;z-index:0;}
.css-1kxonj9{width:100%;position:relative;}
.css-1ncwqrm{position:absolute;top:0;right:0;width:100%;}
@media (min-width:0px){
.css-1ncwqrm{max-width:36px;max-height:36px;}
}
@media (min-width:600px){
.css-1ncwqrm{max-width:40px;max-height:40px;}
}
.css-145il57{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;position:relative;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;}
.css-y8muc4{margin:0;font-family:'sukhumvittadmai';font-weight:400;font-size:1rem;line-height:1.5;font-size:14px;line-height:20px;width:auto;-webkit-text-decoration:none;text-decoration:none;text-overflow:ellipsis;overflow:hidden;white-space:initial;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-align:left;margin-bottom:7.68px;padding-top:0px;}
@media (min-width:0px){
.css-y8muc4{font-size:12px;margin-top:16px;height:38px;}
}
@media (min-width:600px){
.css-y8muc4{font-size:14px;margin-top:8px;}
}
@media (min-width:900px){
.css-y8muc4{height:40px;}
}
.css-148xglp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;-webkit-box-flex-wrap:wrap;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:3.27px;position:relative;}
.css-19zcdyn{font-family:'sukhumvittadmai';font-size:0.8125rem;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;height:24px;color:#fff;background-color:#E31F26;border-radius:16px;white-space:nowrap;-webkit-transition:background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;transition:background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;cursor:default;outline:0;-webkit-text-decoration:none;text-decoration:none;border:0;padding:0;vertical-align:middle;box-sizing:border-box;font-size:12px;border-radius:8px;background:rgba(227, 31, 38, 1);font-weight:700;position:relative;top:-2px;visibility:initial;}
.css-tavflp{overflow:hidden;text-overflow:ellipsis;padding-left:8px;padding-right:8px;white-space:nowrap;}
.css-1krjymi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;text-align:right;}
.css-xnjhch{margin:0;font-family:'sukhumvittadmai';font-weight:400;font-size:1rem;line-height:1.75;color:#757575;font-size:14px;color:#303030;font-weight:700;line-height:24px;}
@media (min-width:0px){
.css-xnjhch{font-size:16px;}
}
@media (min-width:600px){
.css-xnjhch{font-size:20px;}
}
.css-1vrveu8{width:100%;margin-left:auto;box-sizing:border-box;margin-right:auto;display:block;padding-left:16px;padding-right:16px;}
@media (min-width:600px){
.css-1vrveu8{padding-left:24px;padding-right:24px;}
}
@media (min-width:1280px){
.css-1vrveu8{max-width:1280px;}
}
@media (min-width:0px){
.css-1vrveu8{padding-left:0px;padding-right:0px;}
}
.css-1ye9ly5{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex-wrap:wrap;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;padding-left:16px;padding-right:12px;padding-bottom:16px;}
@media (min-width:0px){
.css-1ye9ly5{margin-top:-8px;}
.css-1ye9ly5>.MuiGrid-item{padding-top:8px;}
}
@media (min-width:600px){
.css-1ye9ly5{margin-top:-16px;}
.css-1ye9ly5>.MuiGrid-item{padding-top:16px;}
}
@media (min-width:0px){
.css-1ye9ly5{width:calc(100% + 8px);margin-left:-8px;}
.css-1ye9ly5>.MuiGrid-item{padding-left:8px;}
}
@media (min-width:600px){
.css-1ye9ly5 {
    width: calc(100% + 84px);
    margin-left: -48px;
}
.css-1ye9ly5>.MuiGrid-item{padding-left:16px;}
}
.css-1raj6c1{box-sizing:border-box;margin:0;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex-basis:50%;-ms-flex-preferred-size:50%;flex-basis:50%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:50%;}
@media (min-width:600px){
.css-1raj6c1{-webkit-flex-basis:33.333333%;-ms-flex-preferred-size:33.333333%;flex-basis:33.333333%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:33.333333%;}
}
@media (min-width:900px){
.css-1raj6c1{-webkit-flex-basis:25%;-ms-flex-preferred-size:25%;flex-basis:25%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:25%;}
}
@media (min-width:1280px){
.css-1raj6c1{-webkit-flex-basis:auto;-ms-flex-preferred-size:auto;flex-basis:auto;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;max-width:none;width:auto;}
}
@media (min-width:1440px){
.css-1raj6c1{-webkit-flex-basis:auto;-ms-flex-preferred-size:auto;flex-basis:auto;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;max-width:none;width:auto;}
}
/*! CSS Used from: Embedded */
.css-1br9xiw{flex-direction:column;position:relative;padding:0px;background:rgb(255, 255, 255);border-radius:8px;margin:-13px auto 0px;height:32px;display:grid;max-width:max-content;}
@media (min-width: 0px){
.css-1br9xiw{visibility:hidden;}
}
@media (min-width: 900px){
.css-1br9xiw{visibility:initial;}
}
.css-kvr42m{margin:0px;font-family:"'sukhumvittadmai'";font-weight:400;font-size:14px;color:rgba(0, 0, 0, 0.38);text-decoration-line:line-through;line-height:16px;}
@media (min-width: 0px){
.css-kvr42m{font-size:12px;}
}
@media (min-width: 600px){
.css-xnjhch {
    font-size: 22px;
    font-weight: 800;
}
}
.css-1l6zwpn{margin:0px;font-family:"'sukhumvittadmai'";font-weight:400;font-size:14px;line-height:20px;text-align:left;}
.css-ryhomi{margin:0px;font-family:"'sukhumvittadmai'";font-weight:400;font-size:14px;color:rgb(227, 31, 38);line-height:1;}
@media (min-width: 0px){
.css-ryhomi{font-size:8px;}
}
@media (min-width: 600px){
.css-ryhomi{font-size:10px;}
}
.css-1sbkx7t{margin:0px;font-family:"'sukhumvittadmai'";font-weight:400;font-size:14px;line-height:1;}
@media (min-width: 0px){
.css-1sbkx7t{font-size:8px;}
}
@media (min-width: 600px){
.css-1sbkx7t{font-size:10px;}
}
.css-1701hd5{box-sizing:border-box;display:flex;flex-flow:row wrap;width:100%;-webkit-box-align:center;align-items:center;height:30px;}
@media (min-width: 0px){
.css-1701hd5{margin-top:-3px;}
.css-1701hd5 > .MuiGrid-item{padding-top:3px;}
}
@media (min-width: 600px){
.css-1701hd5{margin-top:-6px;}
.css-1701hd5 > .MuiGrid-item{padding-top:6px;}
}
@media (min-width: 0px){
.css-1701hd5{width:calc(100% + 3px);margin-left:-3px;}
.css-1701hd5 > .MuiGrid-item{padding-left:3px;}
}
@media (min-width: 600px){
.css-1701hd5{width:calc(100% + 6px);margin-left:-6px;}
.css-1701hd5 > .MuiGrid-item{padding-left:6px;}
}
.css-1wxaqej{box-sizing:border-box;margin:0px;flex-direction:row;}
.css-15piqp2{font-family:"'sukhumvittadmai'";display:inline-flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;color:rgba(0, 0, 0, 0.87);background-color:rgba(0, 0, 0, 0.08);white-space:nowrap;transition:background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;cursor:default;outline:0px;text-decoration:none;border:0px;padding:0px;vertical-align:middle;box-sizing:border-box;width:52px;height:16px;font-size:8px;border-radius:4px;}
.css-15piqp2 .MuiChip-label{color:rgba(48, 48, 48, 0.87);padding-left:4px;padding-right:0px;padding-top:0px;}
.css-14vsv3w{overflow:hidden;text-overflow:ellipsis;padding-left:12px;padding-right:12px;white-space:nowrap;}
.css-12tnxpv{box-sizing:border-box;margin:0px;flex-direction:row;}
@media (min-width: 0px){
.css-12tnxpv{display:unset;}
}
@media (min-width: 900px){
.css-12tnxpv{display:initial;}
}
.css-gcepaj{font-family:"'sukhumvittadmai'";display:inline-flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;background-color:rgba(0, 0, 0, 0.08);white-space:nowrap;transition:background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;cursor:default;outline:0px;text-decoration:none;border:0px;padding:0px;vertical-align:middle;box-sizing:border-box;width:52px;height:16px;font-size:8px;border-radius:4px;color:rgba(48, 48, 48, 0.87);}
.css-gcepaj .MuiChip-label{color:rgba(48, 48, 48, 0.87);padding-left:4px;padding-right:0px;padding-top:0px;}
.css-kvc1z8{margin:0px;font-family:"'sukhumvittadmai'";font-weight:400;font-size:14px;line-height:20px;height:20px;}
.css-p96c5m{display:flex;flex-direction:row;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center;height:16px;background:rgba(0, 205, 248, 0.5);border-radius:4px;}
@media (min-width: 0px){
.css-p96c5m{width:65px;}
}
@media (min-width: 600px){
.css-p96c5m{width:70px;}
}
.css-z6ofgo{width:19px;height:16px;background:rgb(0, 205, 248);border-top-left-radius:4px;border-bottom-left-radius:4px;border-right:0.3px dashed rgb(255, 255, 255);display:flex;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center;position:relative;padding-left:3px;}
.css-1vff1u2{height:6px;width:6px;background:rgb(255, 255, 255);position:absolute;border-radius:50%;top:50%;left:0px;transform:translate(-50%, -50%);}
.css-4u52q2{width:51px;height:16px;display:flex;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center;position:relative;padding-right:5px;}
.css-129x66n{height:6px;width:6px;background:rgb(255, 255, 255);position:absolute;border-radius:50%;top:50%;left:auto;right:-6px;transform:translate(-50%, -50%);}
.css-m8dgqs{margin:0px;line-height:20px;color:rgb(0, 157, 210);font-family:"'sukhumvittadmai'";font-style:normal;font-weight:normal;font-size:8px;padding-top:0px;}
.css-oy5q08{box-sizing:border-box;margin:0px;flex-direction:row;}
@media (min-width: 0px){
.css-oy5q08{display:none;}
}
@media (min-width: 900px){
.css-oy5q08{display:initial;}
}
</style>
