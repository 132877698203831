import http from "../utils/http"

/**
 * รับข้อมูลการเริ่มต้นคําสั่งซื้อ
 * @param {object} params
 */
export function payment(params) {
    return http({
        url: "/groupbuy/api/ordercreate/payment",
        data: params,
        forceLogin: true
    })
}

/**
 * ขอสิทธิ์ในการตรวจสอบรายละเอียดผลิตภัณฑ์สําหรับการซื้อแบบกลุ่ม
 * @param {object} params
 */
export function evaluateConfig() {
    return http({
        url: "/api/goodsevaluate/config",
        data: {},
        forceLogin: true
    })
}

/**
 * รับข้อมูลการเริ่มต้นคําสั่งซื้อ
 * @param {object} params
 */
export function calculate(params) {
    return http({
        url: "/groupbuy/api/ordercreate/calculate",
        data: params,
        forceLogin: true
    })
}

/**
 * การสร้างคําสั่งซื้อ
 * @param {object} params
 */
export function orderCreate(params) {
    return http({
        url: "/groupbuy/api/ordercreate/create",
        data: params,
        forceLogin: true
    })
}

/**
 * รายการสินค้า
 * @param {object} params
 */
export function goodsPage(params) {
    return http({
        url: "/groupbuy/api/goods/page",
        data: params,
        forceLogin: true
    })
}

/**
 * รายละเอียดสินค้า
 * @param {object} params
 */
export function goodsSkuDetail(params) {
    return http({
        url: "/groupbuy/api/goods/detail",
        data: params,
        forceLogin: true
    })
}

/**
 * ข้อมูลผลิตภัณฑ์
 * @param {object} params
 */
export function goodsSkuInfo(params) {
    return http({
        url: "/groupbuy/api/goods/info",
        data: params,
        forceLogin: true
    })
}
